import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { Suspense, useEffect } from "react";
import { ToastContainer } from "react-toastify";

import { axiosInterceptor } from "./config/AxiosConfig";
import store from "./Store/index";
import Routes from "./routes";
import Loader from "./component/common/Loader";
import { AUTHENTICATE_FAILED } from "./Store/Auth/Type";
import { authenticate } from "./Store/Auth/Action";
import { getCompany } from "./Store/Company/Action";
import { raygunConfig } from "./config/RaygunConfig";

const token = localStorage.getItem("auth_token")
  ? JSON.parse(localStorage.getItem("auth_token"))
  : undefined;
const loggedInUser = localStorage.getItem("userData")
  ? JSON.parse(localStorage.getItem("userData"))
  : undefined;

if (token && loggedInUser) {
  store.dispatch(authenticate(loggedInUser));
  store.dispatch(getCompany());
} else {
  store.dispatch({ type: AUTHENTICATE_FAILED });
}

axiosInterceptor(token);
raygunConfig();

function App() {
  useEffect(() => {
    document.body.classList.add("theme-cyan");
  }, []);

  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Suspense fallback={<Loader />}>
            <div id="wrapper">
              <Routes />
            </div>
          </Suspense>
        </BrowserRouter>
      </Provider>
      <ToastContainer />
    </>
  );
}

export default App;
