
import { combineReducers } from "redux"
import AddressReducer from "./Address/Reducer"
import ContactReducer from "./Contact/Reducer"
import AuthReducer from "./Auth/Reducer"
import CompanyReducer from "./Company/Reducer"
import MasterReducer from "./Master/Reducer"
import OrganizationReducer from "./Organization/Reducer"
import RolesReducer from "./Roll/Reducer"
import SalesPersonReducer from "./User/Reducer"
import SupplierReducer from "./Supplier/Reducer"
import ProductReducer from "./Product/Reducer"
import ReminderReducer from "./TaskReminder/Reducer"
import QuotationReducer from "./Quotation/Reducer"
import DashboardReducer from "./DashBoard/Reducers"
import ActivityReducer from "./Activity/Reducer"


const rootReducer = combineReducers({
  auth: AuthReducer,
  company: CompanyReducer,
  user: SalesPersonReducer,
  roles: RolesReducer,
  master: MasterReducer,
  organization: OrganizationReducer,
  address: AddressReducer,
  contact: ContactReducer,
  supplier: SupplierReducer,
  product: ProductReducer,
  reminder: ReminderReducer,
  quotation: QuotationReducer,
  dashboard: DashboardReducer,
  activity: ActivityReducer
})

export default rootReducer