/** Get User Count */

export const GET_USER_COUNT_SUCCESS = 'GET_USER_COUNT_SUCCESS';
export const GET_USER_COUNT_ERROR = 'GET_USER_COUNT_ERROR';


/** Get user detail */

export const GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS';
export const GET_USER_DETAIL_ERROR = 'GET_USER_DETAIL_ERROR';

/** Get sales persons */

export const GET_SALES_PERSONS_SUCCESS = 'GET_SALES_PERSONS_SUCCESS';
export const GET_SALES_PERSONS_ERROR = 'GET_SALES_PERSONS_ERROR';

/** Get user info */

export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR';

/** Add user */
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_ERROR = 'ADD_USER_ERROR'

/** update user */
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

/** delete user */
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR'

/** Invite sales person */

export const INVITE_SALES_PERSON_SUCCESS = 'INVITE_SALES_PERSON_SUCCESS';
export const INVITE_SALES_PERSON_ERROR = 'INVITE_SALES_PERSON_ERROR';

/** Register sales person */

export const REGISTER_SALES_PERSON_SUCCESS = 'REGISTER_SALES_PERSON_SUCCESS';
export const REGISTER_SALES_PERSON_ERROR = 'REGISTER_SALES_PERSON_ERROR'; 