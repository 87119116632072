import * as ACTION_TYPES from './Type'

const initialState = {
    roleData: [],
    rolePermission: [],
}

const RolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_ROLES_SUCCESS:
            return {
                ...state,
                roleData: action.payload

            }
        case ACTION_TYPES.GET_ROLES_ERROR:
            return {
                ...state,
                roleData: []
            }
        case ACTION_TYPES.GET_ROLES_PERMISSION_SUCCESS:
            return {
                ...state,
                rolePermission: action.payload

            }
        case ACTION_TYPES.GET_ROLES_PERMISSION_ERROR:
            return {
                ...state,
                rolePermission: []

            }

        default: return state
    }
}

export default RolesReducer