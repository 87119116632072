export const {
  REACT_APP_API_BASE: API_BASE,
  // RAYGUN_KEY: RAYGUN_API_KEY,
  // REACT_APP_API_BASE_BASE_URL_IMAGE: BASE_URL_imge,
  // REACT_APP_API_BASE_BASE_URL_BASE_PROFILE: BASE_PRIFILE
} = process.env;

//  Auth Moduke

export const API_LOGIN = `${API_BASE}Authenticate/ValidateUserLogin`;
export const API_FORGOT_PASSWORD = `${API_BASE}Authenticate/ForgotPassword`;
export const API_RESET_PASSWORD = `${API_BASE}Authenticate/ResetPassword`;
export const API_CHANGE_PASSWORD = `${API_BASE}Authenticate/ChangePassword`;
export const API_LOGOUT = `${API_BASE}Authenticate/Logout`;

// Company module

export const API_GET_COMPANY = `${API_BASE}Company/GetCompanyInfo`;
export const API_UPDATE_COMPANY = `${API_BASE}Company/UpdateCompany`;
// export const API_UPDATE_COMPANY = `${API_BASE}Company/ManageCompany`

// User

export const API_GET_USER_COUNT = `${API_BASE}User/GetUserCount`;
export const API_ADD_FIRST_USER = `${API_BASE}User/AddFirstUser`;
export const API_GET_USER_DETAIL = `${API_BASE}User/GetUserDetails`;
export const API_GET_SALES_PERSON = `${API_BASE}User/GetSalesPersons`;
export const API_GET_USER_INFO = `${API_BASE}User/GetUserInfo`;
export const API_ADD_USER = `${API_BASE}User/AddUser`;
export const API_UPDATE_USER = `${API_BASE}User/UpdateUser`;
export const API_DELETE_USER = `${API_BASE}User/DeleteUser`;
export const API_INVITE_SALES_PERSON = `${API_BASE}User/InviteSalesPerson`;
export const API_REGISTER_SALES_PERSON = `${API_BASE}User/RegisterSalesPerson`;

// Roles

export const API_GET_ROLES = `${API_BASE}Role/GetRoles`;
export const API_ADD_ROLES = `${API_BASE}Role/AddRole`;
export const API_UPDATE_ROLE = `${API_BASE}Role/UpdateRole`;
export const API_DELETE_ROLE = `${API_BASE}Role/DeleteRole`;
export const API_GET_ROLE_PERMISSION = `${API_BASE}Role/GetRolePermission`;
export const API_SAVE_ROLE_PERMISSION = `${API_BASE}Role/SaveRolePermission`;

// Master

export const API_GET_EMAIL_TEMPLATE = `${API_BASE}Master/GetEmailTemplates`;
export const API_GET_EMAIL_TEMPLATE_INFO = `${API_BASE}Master/GetEmailTemplateInfo`;
export const API_UPDATE_EMAIL_TEMPLATE = `${API_BASE}Master/UpdateEmailTemplate`;
export const API_GET_INDUSTRY_CODE = `${API_BASE}Master/GetIndustryCodes`;
export const API_GET_INDUSRTY_CODE_INFO = `${API_BASE}Master/GetIndustryCodeInfo`;
export const API_ADD_INDUSTRY_CODE = `${API_BASE}Master/AddIndustryCode`;
export const API_UPDATE_INDUSTRY_CODE = `${API_BASE}Master/UpdateIndustryCode`;
export const API_DELETE_INDUSTRY_CODE = `${API_BASE}Master/DeleteIndustryCode`;
export const API_GET_QUOTATION_STATUS = `${API_BASE}Master/GetQuotationStatus`;
export const API_GET_QUOTATION_STATUS_INFO = `${API_BASE}Master/GetQuotationStatusInfo`;
export const API_ADD_QUOTATION_STATUS = `${API_BASE}Master/AddQuotationStatus`;
export const API_UPDATE_QUOTATION_STATUS = `${API_BASE}Master/UpdateQuotationStatus`;
export const API_DELETE_QUOTATION_STATUS = `${API_BASE}Master/DeleteQuotationStatus`;
export const API_GET_QUOTATION_ITEM = `${API_BASE}QuotationItem/GetQuotationItems`;
export const API_GET_QUOTATION_ITEM_INFO = `${API_BASE}QuotationItem/GetQuotationItemInfo`;
export const API_ADD_QUOTATION_ITEM = `${API_BASE}QuotationItem/AddQuotationItem`;
export const API_UPDATE_QUOTATION_ITEM = `${API_BASE}QuotationItem/UpdateQuotationItem`;
export const API_DELETE_QUOTATION_ITEM = `${API_BASE}QuotationItem/DeleteQuotationItem`;
export const API_GET_COUNTRY = `${API_BASE}Master/GetCountries`;
export const API_UPDATE_QUOTATION_SEQUENCE = `${API_BASE}Master/UpdateQuotationStatusDisplayOrder`;

// Organisation

export const API_GET_ORGANIZATION = `${API_BASE}Organization/GetOrganizations`;
export const API_GET_ORGANIZATION_INFO = `${API_BASE}Organization/GetOrganizationInfo`;
export const API_ADD_ORGANIZATION = `${API_BASE}Organization/AddOrganization`;
export const API_UPDATE_ORGANIZATION = `${API_BASE}Organization/UpdateOrganization`;
export const API_DELETE_ORGANIZATION = `${API_BASE}Organization/DeleteOrganization`;
export const API_UPLOAD_ORGANIZATIONS_CSV = `${API_BASE}Organization/UploadCSVOrganization`;

// Address

export const API_GET_ADDRESS = `${API_BASE}Address/GetAddressList`;
export const API_GET_ORGANIZATION_ADDRESS_LIST = `${API_BASE}Address/GetOrganizationAddressList`;
export const API_GET_ADDRESS_INFO = `${API_BASE}Address/GetAddressInfo`;
export const API_ADD_ADDRESS = `${API_BASE}Address/AddAddress`;
export const API_UPDATE_ADDRESS = `${API_BASE}Address/UpdateAddress`;
export const API_DELETE_ADDRESS = `${API_BASE}Address/DeleteAddress`;

// Contact

export const API_GET_CONTACT = `${API_BASE}Contact/GetContacts`;
export const API_GET_ORGANIZATION_CONTACT = `${API_BASE}Contact/GetOrganizationContacts`;
export const API_GET_CONTACT_INFO = `${API_BASE}Contact/GetContactInfo`;
export const API_ADD_CONTACT = `${API_BASE}Contact/AddContact`;
export const API_UPDATE_CONTACT = `${API_BASE}Contact/UpdateContact`;
export const API_DELETE_CONTACT = `${API_BASE}Contact/DeleteContact`;
export const API_UPLOAD_CONTACTS_CSV = `${API_BASE}Contact/UploadCSVContact`;
// Supplier

export const API_GET_SUPPLIER = `${API_BASE}Supplier/GetSuppliers`;
export const API_GET_SUPPLIER_INFO = `${API_BASE}Supplier/GetSupplierInfo`;
export const API_ADD_SUPPLIER = `${API_BASE}Supplier/AddSupplier`;
export const API_UPDATE_SUPPLIER = `${API_BASE}Supplier/UpdateSupplier`;
export const API_DELETE_SUPPLIER = `${API_BASE}Supplier/DeleteSupplier`;

// Product

export const API_GET_PRODUCT = `${API_BASE}Product/GetProducts`;
export const API_GET_PRODUCT_INFO = `${API_BASE}Product/GetProductInfo`;
export const API_ADD_PRODUCT = `${API_BASE}Product/AddProduct`;
export const API_UPDATE_PRODUCT = `${API_BASE}Product/UpdateProduct`;
export const API_DELETE_PRODUCT = `${API_BASE}Product/DeleteProduct`;

// Task Reminder

export const API_GET_TASK_REMINDER = `${API_BASE}TaskReminder/GetTaskReminders`;
export const API_GET_TASK_REMINDER_INFO = `${API_BASE}TaskReminder/GetTaskReminderInfo`;
export const API_ADD_TASK_REMINDER = `${API_BASE}TaskReminder/AddTaskReminder`;
export const API_UPDATE_TASK_REMINDER = `${API_BASE}TaskReminder/UpdateTaskReminder`;
export const API_DELETE_TASK_REMINDER = `${API_BASE}TaskReminder/DeleteTaskReminder`;

// Quotation

export const API_GET_QUOTATION = `${API_BASE}Quotation/GetQuotations`;
export const API_GET_ORGANIZATION_QUOTATION = `${API_BASE}Quotation/GetOrganizationQuotations`;
export const API_GET_QUOTATION_INFO = `${API_BASE}Quotation/GetQuotationInfo`;
export const API_ADD_QUOTATION = `${API_BASE}Quotation/AddQuotation`;
export const API_UPDATE_QUOTATION = `${API_BASE}Quotation/UpdateQuotation`;
export const API_UPDATE_QUOTATION_STATUS_CHANGE = `${API_BASE}Quotation/UpdateQuotationStatus`;
export const API_DELETE_QUOTATION = `${API_BASE}Quotation/DeleteQuotation`;

// DashBoard

export const API_GET_CARD_VALUE = `${API_BASE}Dashboard/GetData`;
export const API_GET_CHART_DATA = `${API_BASE}Dashboard/GetChartData`;

// contact method

export const API_GET_CONTACT_METHOD = `${API_BASE}ContactMethod/GetContactMethodList`;
export const API_ADD_CONTACT_METHOD = `${API_BASE}ContactMethod/AddContactMethod/`;
export const API_UPDATE_CONTACT_METHOD = `${API_BASE}ContactMethod/UpdateContactMethod`;
export const API_DELETE_CONTACT_METHOD = `${API_BASE}ContactMethod/DeleteContactMethod`;
export const API_GET_COUNT = `${API_BASE}Contact/GetContactWedgetInfo`;

// Activity

export const API_GET_ACTIVITY_LIST = `${API_BASE}Activity/GetActivityTypeList`;
export const API_ADD_ACTIVITY_DETAIL = `${API_BASE}Activity/AddActivityDetails`;
export const API_ADD_ACTIVITY_TYPE = `${API_BASE}Activity/AddActivityType/`;
export const API_UPDATE_ACTIVITY_TYPE = `${API_BASE}Activity/UpdateActivityType`;
export const API_DELETE_ACTIVITY_TYPE = `${API_BASE}Activity/DeleteActivityType/`;
export const API_GET_ACTIVITY_TYPE_DETAIL = `${API_BASE}Activity/GetActivityTypeDetails`;
export const API_UPDATE_ACTIVITY_DETAIL = `${API_BASE}Activity/UpdateActivityDetails`;
