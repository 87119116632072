// /**
//  * Axios config  setup
//  * Set interceptor for global api response error handling
//  * Set access token in headers
//  */
import axios from "axios";
import store from "../Store";
import { LOGOUT } from "../Store/Auth/Type";

export const axiosInterceptor = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  axios.interceptors.request.use(
    (request) => {
      return request;
    },
    (error) => {
      if (error?.response) {
        if (
          error.response.status === 401 ||
          error.response.status === 500 ||
          error.response.status === 404
        ) {
          localStorage.clear();
          store.dispatch({
            type: LOGOUT,
          });
          return Promise.reject(error);
        } else if (error?.response?.status === 701) {
          window.location.pathname = "/contactUs";
          return Promise.reject(error);
        } else return Promise.reject(error);
      } else if (error?.request) {
        let err = {
          response: {
            data: {
              message: "Something went wrong,Please try again later!!!",
            },
          },
        };
        return Promise.reject(err);
      }
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response) {
        if (
          error.response.status === 401 ||
          error.response.status === 500
          // || error.response.status === 404
        ) {
          localStorage.clear();
          store.dispatch({
            type: LOGOUT,
          });
          return Promise.reject(error);
        } else if (error?.response?.status === 701) {
          window.location.pathname = "/contactUs";
          return Promise.reject(error);
        } else return Promise.reject(error);
      } else if (error?.request) {
        let err = {
          response: {
            data: {
              message: "Something went wrong,Please try again later!!!",
            },
          },
        };
        return Promise.reject(err);
      }
    }
  );
};
