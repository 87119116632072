export const GET_QUOTATION_SUCCESS = 'GET_QUOTATION_SUCCESS';
export const GET_QUOTATION_ERROR = 'GET_QUOTATION_ERROR';

export const GET_ORGANIZATION_QUOTATION_SUCCESS = 'GET_ORGANIZATION_QUOTATION_SUCCESS';
export const GET_ORGANIZATION_QUOTATION_ERROR = 'GET_ORGANIZATION_QUOTATION_ERROR';

export const GET_QUOTATION_INFO_SUCCESS = 'GET_QUOTATION_INFO_SUCCESS';
export const GET_QUOTATION__INFO_ERROR = 'GET_QUOTATION__INFO_ERROR';

export const ADD_QUOTATION_SUCCESS = 'ADD_QUOTATION_SUCCESS';
export const ADD_QUOTATION__ERROR = 'ADD_QUOTATION__ERROR';

export const UPDATE_QUOTATION_SUCCESS = 'UPDATE_QUOTATION_SUCCESS';
export const UPDATE_QUOTATION__ERROR = 'UPDATE_QUOTATION__ERROR';

export const DELETE_QUOTATION_SUCCESS = 'DELETE_QUOTATION_SUCCESS';
export const DELETE_QUOTATION__ERROR = 'DELETE_QUOTATION__ERROR';

export const NET_AMOUNT = "NET_AMOUNT"

export const UPDATE_QUOTATION_STATUS_SUCCESS = 'UPDATE_QUOTATION_STATUS_SUCCESS';
export const UPDATE_QUOTATION_STATUS__ERROR = 'UPDATE_QUOTATION_STATUS__ERROR';