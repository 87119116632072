export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS';
export const GET_CONTACT_ERROR = 'GET_CONTACT_ERROR';

export const GET_ORGANIZATION_CONTACT_SUCCESS = 'GET_ORGANIZATION_CONTACT_SUCCESS';
export const GET_ORGANIZATION_CONTACT_ERROR = 'GET_ORGANIZATION_CONTACT_ERROR';

export const GET_CONTACT_INFO_SUCCESS = 'GET_CONTACT_INFO_SUCCESS';
export const GET_CONTACT__INFO_ERROR = 'GET_CONTACT__INFO_ERROR';

export const ADD_CONTACT_SUCCESS = 'ADD_CONTACT_SUCCESS';
export const ADD_CONTACT__ERROR = 'ADD_CONTACT__ERROR';

export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT__ERROR = 'UPDATE_CONTACT__ERROR';

export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT__ERROR = 'DELETE_CONTACT__ERROR';

export const GET_CONTACT_METHOD_SUCCESS = 'GET_CONTACT_METHOD_SUCCESS';
export const GET_CONTACT_METHOD_ERROR = 'GET_CONTACT_METHOD_ERROR';

export const ADD_CONTACT_METHOD_SUCCESS = 'ADD_CONTACT_METHOD_SUCCESS';
export const ADD_CONTACT_METHOD__ERROR = 'ADD_CONTACT_METHOD__ERROR';

export const UPDATE_CONTACT_METHOD_SUCCESS = 'UPDATE_CONTACT_METHOD_SUCCESS';
export const UPDATE_CONTACT_METHOD__ERROR = 'UPDATE_CONTACT_METHOD__ERROR';

export const DELETE_CONTACT_METHOD_SUCCESS = 'DELETE_CONTACT_METHOD_SUCCESS';
export const DELETE_CONTACT_METHOD__ERROR = 'DELETE_CONTACT_METHOD__ERROR';

export const GET_COUNT_SUCCESS = 'GET_COUNT_SUCCESS';
export const GET_COUNT_ERROR = 'GET_COUNT_ERROR';
