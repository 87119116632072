import * as ACTION_TYPES from './Type'

const initialState = {
    contactData: [],
    contactInfo: {},
    detailContact: [],
    contactMethod: [],
    count: {}
}

const ContactReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_CONTACT_SUCCESS:
            return {
                ...state,
                contactData: action.payload
            }
        case ACTION_TYPES.GET_CONTACT_ERROR:
            return {
                ...state,
                contactData: []
            }
        case ACTION_TYPES.GET_CONTACT_INFO_SUCCESS:
            return {
                ...state,
                contactInfo: action.payload
            }
        case ACTION_TYPES.GET_CONTACT__INFO_ERROR:
            return {
                ...state,
                contactInfo: {}
            }
        case ACTION_TYPES.GET_ORGANIZATION_CONTACT_SUCCESS:
            return {
                ...state,
                detailContact: action.payload,
            }
        case ACTION_TYPES.GET_ORGANIZATION_CONTACT_ERROR:
            return {
                ...state,
                detailContact: []
            }
        case ACTION_TYPES.GET_CONTACT_METHOD_SUCCESS:
            return {
                ...state,
                contactMethod: action.payload
            }
        case ACTION_TYPES.GET_CONTACT_METHOD_ERROR:
            return {
                ...state,
                contactMethod: {}
            }
        case ACTION_TYPES.GET_COUNT_SUCCESS:
            return {
                ...state,
                count: action.payload
            }
        case ACTION_TYPES.GET_COUNT_ERROR:
            return {
                ...state,
                count: {}
            }
        default: return state;
    }
}

export default ContactReducer
