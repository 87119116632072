export const GET_SUPPLIER_SUCCESS = 'GET_SUPPLIER_SUCCESS';
export const GET_SUPPLIER_ERROR = 'GET_SUPPLIER_ERROR';

export const GET_SUPPLIER_INFO_SUCCESS = 'GET_SUPPLIER_INFO_SUCCESS';
export const GET_SUPPLIER__INFO_ERROR = 'GET_SUPPLIER__INFO_ERROR';

export const ADD_SUPPLIER_SUCCESS = 'ADD_SUPPLIER_SUCCESS';
export const ADD_SUPPLIER__ERROR = 'ADD_SUPPLIER__ERROR';

export const UPDATE_SUPPLIER_SUCCESS = 'UPDATE_SUPPLIER_SUCCESS';
export const UPDATE_SUPPLIER__ERROR = 'UPDATE_SUPPLIER__ERROR';

export const DELETE_SUPPLIER_SUCCESS = 'DELETE_SUPPLIER_SUCCESS';
export const DELETE_SUPPLIER__ERROR = 'DELETE_SUPPLIER__ERROR';