import React from 'react'

import logo from '../../assets/images/cnctlogo.svg'

export default function Loader() {
    return (
        <>
             <div className="page-loader-wrapper bg-white text-center h-100 custom-loader">
                <div className="align-content-center align-items-center d-flex flex-wrap h-100 justify-content-center loader">
                    <div className="m-t-30"><img src={logo} width="80" height="80" alt="cnct"/></div>
                    <p className="w-100">Please wait...</p>
                </div>
            </div>
        </>
    )
}
