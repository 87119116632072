import * as ACTION_TYPES from './Type'

const initialState = {
    productData: [],
    productInfo: {},
}

const ProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_PRODUCT_SUCCESS:
            return {
                ...state,
                productData: action.payload
            }
        case ACTION_TYPES.GET_PRODUCT_ERROR:
            return {
                ...state,
                productData: []
            }
        case ACTION_TYPES.GET_PRODUCT_INFO_SUCCESS:
            return {
                ...state,
                productInfo: action.payload
            }
        case ACTION_TYPES.GET_PRODUCT__INFO_ERROR:
            return {
                ...state,
                productInfo: {}
            }
        default: return state;
    }
}

export default ProductReducer
