export const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS';
export const GET_ADDRESS_ERROR = 'GET_ADDRESS_ERROR';

export const GET_ORGANIZATION_ADDRESS_LIST_SUCCESS = 'GET_ORGANIZATION_ADDRESS_LIST_SUCCESS';
export const GET_ORGANIZATION_ADDRESS_LIST_ERROR = 'GET_ORGANIZATION_ADDRESS_LIST_ERROR';

export const GET_ADDRESS_INFO_SUCCESS = 'GET_ADDRESS_INFO_SUCCESS';
export const GET_ADDRESS__INFO_ERROR = 'GET_ADDRESS__INFO_ERROR';

export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS__ERROR = 'ADD_ADDRESS__ERROR';

export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS__ERROR = 'UPDATE_ADDRESS__ERROR';

export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS__ERROR = 'DELETE_ADDRESS__ERROR';