import * as ACTION_TYPES from './Type'

const initialState = {
    supplierData: [],
    supplierInfo: {},
}

const SupplierReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_SUPPLIER_SUCCESS:
            return {
                ...state,
                supplierData: action.payload
            }
        case ACTION_TYPES.GET_SUPPLIER_ERROR:
            return {
                ...state,
                supplierData: []
            }
        case ACTION_TYPES.GET_SUPPLIER_INFO_SUCCESS:
            return {
                ...state,
                supplierInfo: action.payload
            }
        case ACTION_TYPES.GET_SUPPLIER__INFO_ERROR:
            return {
                ...state,
                supplierInfo: {}
            }
        default: return state;
    }
}

export default SupplierReducer
