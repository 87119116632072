import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import AuthGuard from './config/AuthGuard';
import LoadingPage from './Pages/Authentication/LoadingPage';

const SPRegistration = lazy(() => import('./Pages/Authentication/SPRegistration'))
const Layout = lazy(() => import('./component/layout/index'))
const Login = lazy(() => import('./Pages/Authentication/Login'))
const CreateAdmin = lazy(() => import('./Pages/CreateAdmin'))
const ForgotPassword = lazy(() => import('./Pages/Authentication/ForgotPassword'))
const NewPassword = lazy(() => import('./Pages/Authentication/NewPassword'))
// const ChangePassword = lazy(() => import('./Pages/Authentication/ChangePassword'))
const Dashboard = lazy(() => import('./Pages/dashboard'))
const Profile = lazy(() => import('./Pages/Profile/Profile.js'))
const ManageUser = lazy(() => import('./Pages/ManageUser'))
const ManageRoll = lazy(() => import('./Pages/ManageRoll'))
const ManageComapany = lazy(() => import('./Pages/ManageComapany'))
const PermissionAccess = lazy(() => import('./component/ManageRoll/PermissionAccess'))
const IndustryCode = lazy(() => import('./Pages/IndustryCode'))

const EmailTemplate = lazy(() => import('./Pages/EmailTemplate'))
const EditTemplate = lazy(() => import('./component/EmailTemplate/EditTemplate'))

const Organization = lazy(() => import('./Pages/Organization'))
const AddOrganization = lazy(() => import('./component/Organization/AddOrganization'))
const EditOrganization = lazy(() => import('./component/Organization/Edit organization'))

const Address = lazy(() => import('./Pages/Address'))
const AddAddress = lazy(() => import('./component/Address/AddAddress'))
const EditAddress = lazy(() => import('./component/Address/EditAddress'))

const Contact = lazy(() => import('./component/Contact/ContactTable'))
const AllContact = lazy(() => import('./Pages/Contact'))
const AddContact = lazy(() => import('./component/Contact/AddContact'))
const EditContact = lazy(() => import('./component/Contact/EditContact'))
const ViewContact = lazy(() => import('./component/Contact/ViewContact'))

const Supplier = lazy(() => import('./Pages/Supplier'))

const Product = lazy(() => import('./Pages/Product'))
const AddProduct = lazy(() => import('./component/Product/AddProduct'))
const EditProduct = lazy(() => import('./component/Product/EditProduct'))

const TaskReminder = lazy(() => import('./Pages/TaskReminder'))
const AddTaskReminder = lazy(() => import('./component/TaskReminder/AddTaskReminder'))
const EditTaskReminder = lazy(() => import('./component/TaskReminder/EditTaskReminder'))

const QuotationStatus = lazy(() => import('./Pages/QuotationStatus'))
const AllQuotation = lazy(() => import('./Pages/Quotation'))
const Quotation = lazy(() => import('./component/Quotation/QuotationTable'))
const AddQuotation = lazy(() => import('./component/Quotation/AddQuotation'))
const EditQuotation = lazy(() => import('./component/Quotation/EditQuotation'))
const ViewQuotation = lazy(() => import('./component/Quotation/ViewQuotation'))

const QuotationItem = lazy(() => import('./Pages/QuotationItem'))

const ChangePassword = lazy(() => import('./Pages/ChangePassword'))

const ContactUs = lazy(() => import('./Pages/ContactUs'))
const ContactStatus = lazy(() => import('./Pages/ContactStatus'))

const TaskBoard = lazy(() => import('./component/Quotation/QuotationTaskBoard'))

const ActivityType = lazy(() => import('./Pages/ActivityType'))

const Routing = () => {
    return (
        <Routes>
            <Route path="/loading" element={<LoadingPage />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/create-admin" element={<CreateAdmin />} />
            <Route path="*" element={<Navigate replace to="/" />} />
            <Route path="/" element={<Navigate replace to="/loading" />} />
            <Route index path="/login" element={<Login />} />
            <Route index path="/forgot-password" element={<ForgotPassword />} />
            <Route index path="/sales-person-registration" element={<SPRegistration />} />
            {/* <Route index path="/change-password" element={<ChangePassword />} /> */}
            <Route index path="/new-password" element={<NewPassword />} />

            <Route index path="/contactUs" element={<ContactUs />} />

            <Route path="/" element={<AuthGuard><Layout /></AuthGuard>}>
                <Route path="/" element={<Navigate replace to="/dashboard" />} />
                <Route index path="/dashboard" element={<Dashboard />} />

                <Route path="/profile" element={<Profile />} />

                <Route path="/Manage-User" element={<ManageUser />} />
                <Route path="/Manage-Role" element={<ManageRoll />} />
                <Route path="/Manage-Role/Allow-Permissions/:id" element={<PermissionAccess />} />

                <Route path="/Manage-Company" element={<ManageComapany />} />

                <Route path="/Industry-Code" element={<IndustryCode />} />

                <Route path="/email-template" element={<EmailTemplate />} />
                <Route path="/edit-template/:id" element={<EditTemplate />} />

                <Route path="/organization" element={<Organization />} />
                <Route path="/add-organization" element={<AddOrganization />} />
                <Route path="/edit-organization/:id" element={<EditOrganization />} />
                <Route path="/organization/:orgId/contact/" element={<Contact />} />
                <Route path='/organization/:orgId/edit-contact/:id' element={<EditContact />} />
                <Route path="/organization/:orgId/add-contact" element={<AddContact />} />
                <Route path="/organization/:orgId/view-contact/:id" element={<ViewContact />} />
                <Route path="/organization/:orgId/quotation/" element={<Quotation />} />
                <Route path="/organization/:orgId/add-quotation" element={<AddQuotation />} />
                <Route path="/organization/:orgId/edit-quotation/:id" element={<EditQuotation />} />
                <Route path="/organization/:orgId/view-quotation/:id" element={<ViewQuotation />} />


                <Route path="/address/:orgId" element={<Address />} />
                <Route path="/organization/:orgId/add-address/:id" element={<AddAddress />} />
                <Route path='/organization/:orgId/edit-address/:id' element={<EditAddress />} />

                <Route path="/all-contact" element={<AllContact />} />
                <Route path="/add-contact" element={<AddContact />} />
                <Route path=':orgId/edit-contact/:id' element={<EditContact />} />
                <Route path="/view-contact/:id" element={<ViewContact />} />

                <Route path="/supplier" element={<Supplier />} />
                <Route path='/product' element={<Product />} />
                <Route path="/add-product" element={<AddProduct />} />
                <Route path='/edit-product/:id' element={<EditProduct />} />

                <Route path="/taskReminder" element={<TaskReminder />} />
                <Route path="/add-task-reminder" element={<AddTaskReminder />} />
                <Route path="/edit-task-reminder/:id" element={<EditTaskReminder />} />

                <Route path="/quotation-status" element={<QuotationStatus />} />
                <Route path="/all-quotation" element={<AllQuotation />} />
                <Route path="/add-quotation" element={<AddQuotation />} />
                <Route path="/:orgId/edit-quotation/:id" element={<EditQuotation />} />
                <Route path="/edit-quotation/:id" element={<EditQuotation />} /> {/* used in task board */}
                <Route path="/quotation-item" element={<QuotationItem />} />
                <Route path="/view-quotation/:id" element={<ViewQuotation />} />

                <Route path="/contact-status" element={<ContactStatus />} />

                <Route path="/activity-type" element={<ActivityType />} />

                <Route path="/task-board" element={<TaskBoard />} />

                <Route path="/change-password" element={<ChangePassword />} />
            </Route>
        </Routes>
    );
}

export default Routing;