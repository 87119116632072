/** Email template */

export const GET_EMAIL_TEMPLATE_SUCCESS = 'GET_EMAIL_TEMPLATE_SUCCESS'
export const GET_EMAIL_TEMPLATE_ERROR = 'GET_EMAIL_TEMPLATE_ERROR'

export const GET_EMAIL_TEMPLATE_INFO_SUCCESS = 'GET_EMAIL_TEMPLATE_INFO_SUCCESS'
export const GET_EMAIL_TEMPLATE_INFO_ERROR = 'GET_EMAIL_TEMPLATE_INFO_ERROR'

export const UPDATE_EMAIL_TEMPLATE_SUCCESS = 'UPDATE_EMAIL_TEMPLATE_SUCCESS'
export const UPDATE_EMAIL_TEMPLATE_ERROR = 'UPDATE_EMAIL_TEMPLATE_ERROR'

/** Industry code */

export const GET_INDUSTRY_CODE_SUCCESS = 'GET_INDUSTRY_CODE_SUCCESS'
export const GET_INDUSTRY_CODE_ERROR = 'GET_INDUSTRY_CODE_ERROR'

export const GET_INDUSTRY_CODE_INFO_SUCCESS = 'GET_INDUSTRY_CODE_INFO_SUCCESS'
export const GET_INDUSTRY_CODE_INFO_ERROR = 'GET_INDUSTRY_CODE_INFO_ERROR'

export const ADD_INDUSTRY_CODE_SUCCESS = 'ADD_INDUSTRY_CODE_SUCCESS'
export const ADD_INDUSTRY_CODE_ERROR = 'ADD_INDUSTRY_CODE_ERROR'

export const UPDATE_INDUSTRY_CODE_SUCCESS = 'UPDATE_INDUSTRY_CODE_SUCCESS'
export const UPDATE_INDUSTRY_CODE_ERROR = 'UPDATE_INDUSTRY_CODE_ERROR'

export const DELETE_INDUSTRY_CODE_SUCCESS = 'DELETE_INDUSTRY_CODE_SUCCESS'
export const DELETE_INDUSTRY_CODE_ERROR = 'DELETE_INDUSTRY_CODE_ERROR'

/** Quotation status */

export const GET_QUOTATION_STATUS_SUCCESS = 'GET_QUOTATION_STATUS_SUCCESS'
export const GET_QUOTATION_STATUS_ERROR = 'GET_QUOTATION_STATUS_ERROR'

export const GET_QUOTATION_STATUS__INFO_SUCCESS = 'GET_QUOTATION_STATUS__INFO_SUCCESS'
export const GET_QUOTATION_STATUS__INFO_ERROR = 'GET_QUOTATION_STATUS__INFO_ERROR'

export const ADD_QUOTATION_STATUS_SUCCESS = 'ADD_QUOTATION_STATUS_SUCCESS'
export const ADD_QUOTATION_STATUS_ERROR = 'ADD_QUOTATION_STATUS_ERROR'

export const UPDATE_QUOTATION_STATUS_SUCCESS = 'UPDATE_QUOTATION_STATUS_SUCCESS'
export const UPDATE_QUOTATION_STATUS_ERROR = 'UPDATE_QUOTATION_STATUS_ERROR'

export const DELETE_QUOTATION_STATUS_SUCCESS = 'DELETE_QUOTATION_STATUS_SUCCESS'
export const DELETE_QUOTATION_STATUS_ERROR = 'DELETE_QUOTATION_STATUS_ERROR'

/** Quotation Item */

export const GET_QUOTATION_ITEM_SUCCESS = 'GET_QUOTATION_ITEM_SUCCESS'
export const GET_QUOTATION_ITEM_ERROR = 'GET_QUOTATION_ITEM_ERROR'

export const GET_QUOTATION_ITEM__INFO_SUCCESS = 'GET_QUOTATION_ITEM__INFO_SUCCESS'
export const GET_QUOTATION_ITEM__INFO_ERROR = 'GET_QUOTATION_ITEM__INFO_ERROR'

export const ADD_QUOTATION_ITEM_SUCCESS = 'ADD_QUOTATION_ITEM_SUCCESS'
export const ADD_QUOTATION_ITEM_ERROR = 'ADD_QUOTATION_ITEM_ERROR'

export const UPDATE_QUOTATION_ITEM_SUCCESS = 'UPDATE_QUOTATION_ITEM_SUCCESS'
export const UPDATE_QUOTATION_ITEM_ERROR = 'UPDATE_QUOTATION_ITEM_ERROR'

export const DELETE_QUOTATION_ITEM_SUCCESS = 'DELETE_QUOTATION_ITEM_SUCCESS'
export const DELETE_QUOTATION_ITEM_ERROR = 'DELETE_QUOTATION_ITEM_ERROR'

export const UPDATE_QUOTATION_SEQUENCE_SUCCESS = 'UPDATE_QUOTATION_SEQUENCE_SUCCESS'
export const UPDATE_QUOTATION_SEQUENCE_ERROR = 'UPDATE_QUOTATION_SEQUENCE_ERROR'

/** Country */

export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS'
export const GET_COUNTRY_ERROR = 'GET_COUNTRY_ERROR'