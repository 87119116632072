import * as ACTION_TYPES from './Type'

const initialState = {
    netAmount: '',
    quotationData: [],
    quotationInfo: {},
    detailQuotation: [],
}

const QuotationReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_QUOTATION_SUCCESS:
            return {
                ...state,
                quotationData: action.payload
            }
        case ACTION_TYPES.GET_QUOTATION_ERROR:
            return {
                ...state,
                quotationData: []
            }
        case ACTION_TYPES.GET_QUOTATION_INFO_SUCCESS:
            return {
                ...state,
                quotationInfo: action.payload
            }
        case ACTION_TYPES.GET_QUOTATION__INFO_ERROR:
            return {
                ...state,
                quotationInfo: {}
            }
        case ACTION_TYPES.GET_ORGANIZATION_QUOTATION_SUCCESS:
            return {
                ...state,
                detailQuotation: []
            }
        case ACTION_TYPES.GET_ORGANIZATION_QUOTATION_ERROR:
            return {
                ...state,
                detailQuotation: []
            }
        case ACTION_TYPES.NET_AMOUNT:
            return {
                ...state,
                netAmount: action.payload
            }
        default: return state;
    }
}

export default QuotationReducer
