import * as ACTION_TYPES from './Type'

const initialState = {
    activityData: [],
    activityInfo: {},
    activityList: []
}

const ActivityReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_ACTIVITY_TYPE_SUCCESS:
            return {
                ...state,
                activityData: action.payload
            }
        case ACTION_TYPES.GET_ACTIVITY_TYPE_ERROR:
            return {
                ...state,
                activityData: []
            }
        case ACTION_TYPES.GET_ACTIVITY_LIST_SUCCESS:
            return {
                ...state,
                activityList: action.payload
            }
        case ACTION_TYPES.GET_ACTIVITY_LIST_ERROR:
            return {
                ...state,
                activityList: []
            }
        default: return state;
    }
}

export default ActivityReducer
