export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_ERROR = 'GET_ORGANIZATION_ERROR';

export const GET_ORGANIZATION_INFO_SUCCESS = 'GET_ORGANIZATION_INFO_SUCCESS';
export const GET_ORGANIZATION__INFO_ERROR = 'GET_ORGANIZATION__INFO_ERROR';

export const ADD_ORGANIZATION_SUCCESS = 'ADD_ORGANIZATION_SUCCESS';
export const ADD_ORGANIZATION__ERROR = 'ADD_ORGANIZATION__ERROR';

export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION__ERROR = 'UPDATE_ORGANIZATION__ERROR';

export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const DELETE_ORGANIZATION__ERROR = 'DELETE_ORGANIZATION__ERROR';