export const GET_ACTIVITY_TYPE_SUCCESS = 'GET_ACTIVITY_TYPE_SUCCESS';
export const GET_ACTIVITY_TYPE_ERROR = 'GET_ACTIVITY_TYPE_ERROR';

export const ADD_ACTIVITY_TYPE_SUCCESS = 'ADD_ACTIVITY_TYPE_SUCCESS';
export const ADD_ACTIVITY_TYPE__ERROR = 'ADD_ACTIVITY_TYPE__ERROR';

export const UPDATE_ACTIVITY_TYPE_SUCCESS = 'UPDATE_ACTIVITY_TYPE_SUCCESS';
export const UPDATE_ACTIVITY_TYPE__ERROR = 'UPDATE_ACTIVITY_TYPE__ERROR';

export const DELETE_ACTIVITY_TYPE_SUCCESS = 'DELETE_ACTIVITY_TYPE_SUCCESS';
export const DELETE_ACTIVITY_TYPE__ERROR = 'DELETE_ACTIVITY_TYPE__ERROR';

export const GET_ACTIVITY_LIST_SUCCESS = 'GET_ACTIVITY_LIST_SUCCESS';
export const GET_ACTIVITY_LIST_ERROR = 'GET_ACTIVITY_LIST_ERROR';

export const ADD_ACTIVITY_DETAIL_SUCCESS = 'ADD_ACTIVITY_DETAIL_SUCCESS';
export const ADD_ACTIVITY_DETAIL__ERROR = 'ADD_ACTIVITY_DETAIL__ERROR';

export const UPDATE_ACTIVITY_DETAIL_SUCCESS = 'UPDATE_ACTIVITY_DETAIL_SUCCESS';
export const UPDATE_ACTIVITY_DETAIL__ERROR = 'UPDATE_ACTIVITY_DETAIL__ERROR';
