import * as ACTION_TYPES from './Type'

const initialState = {
    message: '',
    userData: [],
    userInfo: {},
    salesPerson: [],
    count: 0,
}

const SalesPersonReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_USER_COUNT_SUCCESS:
            return {
                ...state,
                count: action.payload
            }
        case ACTION_TYPES.GET_USER_COUNT_ERROR:
            return {
                ...state,
                count: null,
            }
        case ACTION_TYPES.GET_USER_DETAIL_SUCCESS:
            return {
                ...state,
                userData: action.payload
            }
        case ACTION_TYPES.GET_USER_DETAIL_ERROR:
            return {
                ...state,
                userData: []
            }
        case ACTION_TYPES.GET_USER_INFO_SUCCESS:
            return {
                ...state,
                userInfo: action.payload,
            }
        case ACTION_TYPES.GET_USER_INFO_ERROR:
            return {
                ...state,
                userInfo: {}
            }
        case ACTION_TYPES.INVITE_SALES_PERSON_SUCCESS:
            return {
                ...state,
                message: action.payload.message
            }
        case ACTION_TYPES.INVITE_SALES_PERSON_ERROR:
            return {
                ...state,
                message: action.payload.message
            }
        case ACTION_TYPES.GET_SALES_PERSONS_SUCCESS:
            return {
                ...state,
                salesPerson: action.payload
            }
        case ACTION_TYPES.GET_SALES_PERSONS_ERROR:
            return {
                ...state,
                salesPerson: []
            }
        default: return state;
    }
}

export default SalesPersonReducer;