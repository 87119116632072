import * as ACTION_TYPES from './Type'

const initialState = {
    addressData: [],
    addressInfo: {},
    detailAddress: [],
}

const AddressReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_ADDRESS_SUCCESS:
            return {
                ...state,
                addressData: action.payload
            }
        case ACTION_TYPES.GET_ADDRESS_ERROR:
            return {
                ...state,
                addressData: []
            }
        case ACTION_TYPES.GET_ORGANIZATION_ADDRESS_LIST_SUCCESS:
            return {
                ...state,
                detailAddress: action.payload
            }
        case ACTION_TYPES.GET_ORGANIZATION_ADDRESS_LIST_ERROR:
            return {
                ...state,
                detailAddress: []
            }
        case ACTION_TYPES.GET_ADDRESS_INFO_SUCCESS:
            return {
                ...state,
                addressInfo: action.payload
            }
        case ACTION_TYPES.GET_ADDRESS__INFO_ERROR:
            return {
                ...state,
                addressInfo: {}
            }
        default: return state;
    }
}

export default AddressReducer
