import * as ACTION_TYPES from './Type'

const initialState = {
    reminderData: [],
    reminderInfo: {},
}

const ReminderReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_TASK_REMINDER_SUCCESS:
            return {
                ...state,
                reminderData: action.payload
            }
        case ACTION_TYPES.GET_TASK_REMINDER_ERROR:
            return {
                ...state,
                reminderData: []
            }
        case ACTION_TYPES.GET_TASK_REMINDER_INFO_SUCCESS:
            return {
                ...state,
                reminderInfo: action.payload
            }
        case ACTION_TYPES.GET_TASK_REMINDER_INFO_ERROR:
            return {
                ...state,
                reminderInfo: {}
            }
        default: return state;
    }
}

export default ReminderReducer
