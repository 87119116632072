import axios from "axios"
import { API_GET_COMPANY, API_UPDATE_COMPANY } from "../../config/Api"
import * as ACTION_TYPES from './Types'


export const getCompany = () => dispatch => {
    return axios.get(`${API_GET_COMPANY}`)
        .then(res => {
            if (res.status === 200) {
                dispatch({ type: ACTION_TYPES.GET_COMPANY_SUCCESS, payload: res.data })
                return res.data
            } else {
                dispatch({ type: ACTION_TYPES.GET_COMPANY_ERROR, payload: res.data })
                return res.data
            }
        })
        .catch(err => {
            dispatch({ type: ACTION_TYPES.GET_COMPANY_ERROR, payload: err.response })
            throw err
        })
}

export const updateCompany = (data) => dispatch => {
    return axios.post(`${API_UPDATE_COMPANY}`,data)
        .then(res => {
            if (res.data.succeeded === true) {
                dispatch({ type: ACTION_TYPES.UPDATE_COMPANY_SUCCESS, payload: res.data })
                return res.data
            } else {
                dispatch({ type: ACTION_TYPES.UPDATE_COMPANY_ERROR, payload: res.data })
                return res.data
            }
        })
        .catch(err => {
            dispatch({ type: ACTION_TYPES.UPDATE_COMPANY_ERROR, payload: err.response })
            throw err
        })
}