export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_ERROR = 'GET_PRODUCT_ERROR';

export const GET_PRODUCT_INFO_SUCCESS = 'GET_PRODUCT_INFO_SUCCESS';
export const GET_PRODUCT__INFO_ERROR = 'GET_PRODUCT__INFO_ERROR';

export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT__ERROR = 'ADD_PRODUCT__ERROR';

export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT__ERROR = 'UPDATE_PRODUCT__ERROR';

export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT__ERROR = 'DELETE_PRODUCT__ERROR';