import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserCount } from "../../Store/User/Action";
import logo from "../../assets/images/cnctlogo.svg";
import rg4js from "raygun4js";

export default function LoadingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(({ auth: { isLoggedIn } }) => ({
    isLoggedIn,
  }));

  useEffect(() => {
    if (isLoggedIn) return;
    dispatch(getUserCount())
      .then((res) => {
        if (res === 0) {
          navigate("/create-admin");
        } else if (res >= 1) {
          navigate("/login");
        } else {
          navigate("/contactUs");
        }
      })
      .catch((err) =>
        rg4js("send", {
          error: err.message || err.response.data.message,
          tags: [""],
        })
      );
  }, [dispatch, isLoggedIn, navigate]);

  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="theme-cyan">
      <div className="page-loader-wrapper bg-white text-center h-100 custom-loader">
        <div className="align-content-center align-items-center d-flex flex-wrap h-100 justify-content-center loader">
          <div className="m-t-30">
            <img src={logo} width="80" height="80" alt="cnct" />
          </div>
          <p className="w-100">Please wait...</p>
        </div>
      </div>
    </div>
  );
}
