export const GET_TASK_REMINDER_SUCCESS = 'GET_TASK_REMINDER_SUCCESS';
export const GET_TASK_REMINDER_ERROR = 'GET_TASK_REMINDER_ERROR';

export const GET_TASK_REMINDER_INFO_SUCCESS = 'GET_TASK_REMINDER_INFO_SUCCESS';
export const GET_TASK_REMINDER_INFO_ERROR = 'GET_TASK_REMINDER_INFO_ERROR';

export const ADD_TASK_REMINDER_SUCCESS = 'ADD_TASK_REMINDER_SUCCESS';
export const ADD_TASK_REMINDER_ERROR = 'ADD_TASK_REMINDER_ERROR';

export const UPDATE_TASK_REMINDER_SUCCESS = 'UPDATE_TASK_REMINDER_SUCCESS';
export const UPDATE_TASK_REMINDER_ERROR = 'UPDATE_TASK_REMINDER_ERROR';

export const DELETE_TASK_REMINDER_SUCCESS = 'DELETE_TASK_REMINDER_SUCCESS';
export const DELETE_TASK_REMINDER_ERROR = 'DELETE_TASK_REMINDER_ERROR';