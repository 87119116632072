import * as ACTION_TYPES from './Type'

const initialState = {
    organizationData: [],
    organizationInfo: {},
}

const OrganizationReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_ORGANIZATION_SUCCESS:
            return {
                ...state,
                organizationData: action.payload
            }
        case ACTION_TYPES.GET_ORGANIZATION_ERROR:
            return {
                ...state,
                organizationData: []
            }
        case ACTION_TYPES.GET_ORGANIZATION_INFO_SUCCESS:
            return {
                ...state,
                organizationInfo: action.payload
            }
        case ACTION_TYPES.GET_ORGANIZATION__INFO_ERROR:
            return {
                ...state,
                organizationInfo: {}
            }
        default: return state;
    }
}

export default OrganizationReducer
