import * as ACTION_TYPES from './Types'

const initialState = {
    message: '',
    companyData: {},
}

const CompanyReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_COMPANY_SUCCESS:
            localStorage.setItem('companyLogo', JSON.stringify(action?.payload?.logo ?? null))
            return {
                ...state,
                companyData: action.payload,
            }
        case ACTION_TYPES.GET_COMPANY_ERROR:
            return {
                ...state,
                companyData: {},
            }

        default: return state;
    }
}
export default CompanyReducer