import axios from "axios";
import {
  API_ADD_FIRST_USER,
  API_ADD_USER,
  API_DELETE_USER,
  API_GET_SALES_PERSON,
  API_GET_USER_COUNT,
  API_GET_USER_DETAIL,
  API_GET_USER_INFO,
  API_INVITE_SALES_PERSON,
  API_REGISTER_SALES_PERSON,
  API_UPDATE_USER,
} from "../../config/Api";
import * as ACTION_TYPES from "./Type";

export const getUserDetail = () => (dispatch) => {
  return axios
    .get(`${API_GET_USER_DETAIL}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: ACTION_TYPES.GET_USER_DETAIL_SUCCESS,
          payload: res.data,
        });
        return res.data;
      } else {
        dispatch({
          type: ACTION_TYPES.GET_USER_DETAIL_ERROR,
          payload: res.data,
        });
        return res.data;
      }
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.GET_USER_DETAIL_ERROR,
        payload: err.response,
      });
      throw err;
    });
};

export const getSalesPersonsTask = () => (dispatch) => {
  return axios
    .get(`${API_GET_SALES_PERSON}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: ACTION_TYPES.GET_SALES_PERSONS_SUCCESS,
          payload: res.data,
        });
        return res.data;
      } else {
        dispatch({
          type: ACTION_TYPES.GET_SALES_PERSONS_ERROR,
          payload: res.data,
        });
        return res.data;
      }
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.GET_SALES_PERSONS_ERROR,
        payload: err.response,
      });
      throw err;
    });
};

//Use in contact
export const getSalesPersons = (id) => (dispatch) => {
  return axios
    .get(`${API_GET_SALES_PERSON}?userId=${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: ACTION_TYPES.GET_SALES_PERSONS_SUCCESS,
          payload: res.data,
        });
        return res.data;
      } else {
        dispatch({
          type: ACTION_TYPES.GET_SALES_PERSONS_ERROR,
          payload: res.data,
        });
        return res.data;
      }
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.GET_SALES_PERSONS_ERROR,
        payload: err.response,
      });
      throw err;
    });
};

export const getUserInfo = (id) => (dispatch) => {
  return axios
    .get(`${API_GET_USER_INFO}?userId=${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: ACTION_TYPES.GET_USER_INFO_SUCCESS,
          payload: res.data,
        });
        return res.data;
      } else {
        dispatch({ type: ACTION_TYPES.GET_USER_INFO_ERROR, payload: res.data });
        return res.data;
      }
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.GET_USER_INFO_ERROR,
        payload: err.response,
      });
      throw err;
    });
};

export const addUser = (data) => (dispatch) => {
  return axios
    .post(`${API_ADD_USER}`, data)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: ACTION_TYPES.ADD_USER_SUCCESS, payload: res.data });
        return res.data;
      } else {
        dispatch({ type: ACTION_TYPES.ADD_USER_ERROR, payload: res.data });
        return res.data;
      }
    })
    .catch((err) => {
      dispatch({ type: ACTION_TYPES.ADD_USER_ERROR, payload: err.response });
      throw err;
    });
};

export const updateUser = (data) => (dispatch) => {
  return axios
    .post(`${API_UPDATE_USER}`, data)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: ACTION_TYPES.UPDATE_USER_SUCCESS, payload: res.data });
        return res.data;
      } else {
        dispatch({ type: ACTION_TYPES.UPDATE_USER_ERROR, payload: res.data });
        return res.data;
      }
    })
    .catch((err) => {
      dispatch({ type: ACTION_TYPES.UPDATE_USER_ERROR, payload: err.response });
      throw err;
    });
};

export const deleteUser = (id) => (dispatch) => {
  return axios
    .post(`${API_DELETE_USER}?userId=${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: ACTION_TYPES.DELETE_USER_SUCCESS, payload: res.data });
        return res.data;
      } else {
        dispatch({ type: ACTION_TYPES.DELETE_USER_ERROR, payload: res.data });
        return res.data;
      }
    })
    .catch((err) => {
      dispatch({ type: ACTION_TYPES.DELETE_USER_ERROR, payload: err.response });
      throw err;
    });
};

export const inviteSalesPesrson = (data) => (dispatch) => {
  return axios
    .post(`${API_INVITE_SALES_PERSON}`, data)
    .then((res) => {
      if (res.data.succeeded === true) {
        dispatch({
          type: ACTION_TYPES.INVITE_SALES_PERSON_SUCCESS,
          payload: res.data,
        });
        return res.data;
      } else {
        dispatch({
          type: ACTION_TYPES.INVITE_SALES_PERSON_ERROR,
          payload: res.data,
        });
        return res.data;
      }
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.INVITE_SALES_PERSON_ERROR,
        payload: err.response,
      });
      throw err;
    });
};

export const registerSalesPesrson = (data) => (dispatch) => {
  return axios
    .post(`${API_REGISTER_SALES_PERSON}`, data)
    .then((res) => {
      if (res.data.succeeded === true) {
        dispatch({
          type: ACTION_TYPES.REGISTER_SALES_PERSON_SUCCESS,
          payload: res.data,
        });
        return res.data;
      } else {
        dispatch({
          type: ACTION_TYPES.REGISTER_SALES_PERSON_ERROR,
          payload: res.data,
        });
        return res.data;
      }
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.REGISTER_SALES_PERSON_ERROR,
        payload: err.response,
      });
      throw err;
    });
};

export const getUserCount = () => (dispatch) => {
  return axios
    .post(`${API_GET_USER_COUNT}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: ACTION_TYPES.GET_USER_COUNT_SUCCESS,
          payload: res.data,
        });
        return res.data;
      } else {
        dispatch({
          type: ACTION_TYPES.GET_USER_COUNT_ERROR,
          payload: res.data,
        });
        return res.data;
      }
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.GET_USER_COUNT_ERROR,
        payload: err.response,
      });
      throw err;
    });
};
// export const addFirstUser = () => dispatch => {

//     return axios.post(`${API_ADD_FIRST_USER}`)
// }
