/** Login Actions */
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_ERROR = "LOGIN_ERROR"

/** Forgot Password Actions */
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR"

/** Reset password Actions **/
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR"

/**Change password */

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR"

/**Authentication */
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER' 
export const AUTHENTICATE_FAILED = 'AUTHENTICATE_FAILED' 

/** LogOut Actions */
export const LOGOUT = "LOGOUT"