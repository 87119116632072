import * as ACTION_TYPES from './Type'

const initialState = {
    emailTemplate: [],
    templateInfo: {},
    industrycode: [],
    industrycodeInfo: {},
    statuscode: [],
    statusInfo: {},
    country: [],
    QuotationDetailItem : [],
}

const MasterReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_EMAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                emailTemplate: action.payload
            }
        case ACTION_TYPES.GET_EMAIL_TEMPLATE_ERROR:
            return {
                ...state,
                emailTemplate: []
            }
        case ACTION_TYPES.GET_EMAIL_TEMPLATE_INFO_SUCCESS:
            return {
                ...state,
                templateInfo: action.payload
            }
        case ACTION_TYPES.GET_EMAIL_TEMPLATE_INFO_ERROR:
            return {
                ...state,
                templateInfo: {}
            }
        case ACTION_TYPES.GET_INDUSTRY_CODE_SUCCESS:
            return {
                ...state,
                industrycode: action.payload
            }
        case ACTION_TYPES.GET_INDUSTRY_CODE_ERROR:
            return {
                ...state,
                industrycode: []
            }
        case ACTION_TYPES.GET_INDUSTRY_CODE_INFO_SUCCESS:
            return {
                ...state,
                industrycodeInfo: action.payload,
            }
        case ACTION_TYPES.GET_INDUSTRY_CODE_INFO_ERROR:
            return {
                ...state,
                industrycodeInfo: {}
            }
        case ACTION_TYPES.GET_QUOTATION_STATUS_SUCCESS:
            return {
                ...state,
                statuscode: action.payload
            }
        case ACTION_TYPES.GET_QUOTATION_STATUS_ERROR:
            return {
                ...state,
                statuscode: [],
            }
        case ACTION_TYPES.GET_QUOTATION_STATUS__INFO_SUCCESS:
            return {
                ...state,
                statusInfo: action.payload
            }
        case ACTION_TYPES.GET_QUOTATION_STATUS__INFO_ERROR:
            return {
                ...state,
                statusInfo: {}
            }
        case ACTION_TYPES.GET_COUNTRY_SUCCESS:
            return {
                ...state,
                country: action.payload
            }
        case ACTION_TYPES.GET_COUNTRY_ERROR:
            return {
                ...state,
                country: []
            }
        case ACTION_TYPES.GET_QUOTATION_ITEM_SUCCESS:
            return {
                ...state,
                QuotationDetailItem: action.payload
            }
        case ACTION_TYPES.GET_QUOTATION_ITEM_ERROR:
            return {
                ...state,
                QuotationDetailItem: []
            }


        default: return state
    }
}

export default MasterReducer