import axios from 'axios'
import * as ACTION_TYPES from './Type'
import { API_LOGIN, API_FORGOT_PASSWORD, API_RESET_PASSWORD, API_CHANGE_PASSWORD, API_LOGOUT } from '../../config/Api'

export const adminLogin = (data) => dispatch => {
    const config = {
        headers: {
            'Authorization' : null,
            'content-type': 'application/json',
        },
    }
    return axios.post(`${API_LOGIN}`, data, config)
        .then(res => {
            if (res.data.succeeded === true) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.data.token}`
                dispatch({ type: ACTION_TYPES.LOGIN_SUCCESS, payload: res.data })
                return res.data
            } else {
                dispatch({ type: ACTION_TYPES.LOGIN_ERROR, payload: res.data })
                return res.data
            }
        })
        .catch(err => {
            dispatch({ type: ACTION_TYPES.LOGIN_ERROR, payload: err.response })
            throw err
        })
}

export const adminForgotPassword = (data) => dispatch => {
    return axios.post(`${API_FORGOT_PASSWORD}`, data)
        .then(res => {
            if (res.data.succeeded === true) {
                dispatch({ type: ACTION_TYPES.FORGOT_PASSWORD_SUCCESS, payload: res.data })
                return res.data
            } else {
                dispatch({ type: ACTION_TYPES.FORGOT_PASSWORD_ERROR, payload: res.data })
                return res.data
            }
        })
        .catch(err => {
            dispatch({ type: ACTION_TYPES.FORGOT_PASSWORD_ERROR, payload: err.response })
            throw err
        })
}

export const adminResetPassword = (data) => dispatch => {
    return axios.post(`${API_RESET_PASSWORD}`, data)
        .then(res => {
            if (res.data.succeeded === true) {
                dispatch({ type: ACTION_TYPES.RESET_PASSWORD_SUCCESS, payload: res.data })
                return res.data
            } else {
                dispatch({ type: ACTION_TYPES.RESET_PASSWORD_ERROR, payload: res.data })
                return res.data
            }
        })
        .catch(err => {
            dispatch({ type: ACTION_TYPES.RESET_PASSWORD_ERROR, payload: err.response })
            throw err
        })
}

export const adminChangePassword = (data) => dispatch => {
    return axios.post(`${API_CHANGE_PASSWORD}`, data)
        .then(res => {
            if (res.data.succeeded === true) {
                dispatch({ type: ACTION_TYPES.CHANGE_PASSWORD_SUCCESS, payload: res.data })
                return res.data
            } else {
                dispatch({ type: ACTION_TYPES.CHANGE_PASSWORD_ERROR, payload: res.data })
                return res.data
            }
        })
        .catch(err => {
            dispatch({ type: ACTION_TYPES.CHANGE_PASSWORD_ERROR, payload: err.response })
            throw err
        })
}

export const Logout = () => dispatch => {
    return axios.post(`${API_LOGOUT}`)
        .then(res => { dispatch({ type: ACTION_TYPES.LOGOUT }) })
        .catch(err => {
            throw err
        })
}

/** Auth Gaurd */

export const authenticate = user => dispatch => {
    if (user) {
        return dispatch({ type: ACTION_TYPES.AUTHENTICATE_USER, payload: user })
    } else {
        return dispatch({ type: ACTION_TYPES.AUTHENTICATE_FAILED })
    }
}