import * as ACTION_TYPES from './Types'

const initialState = {
    message: '',
    dashboardData: {},
    chartData : [],
}

const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_CARD_VALUE_SUCCESS:
            return {
                ...state,
                dashboardData: action.payload,
            }
        case ACTION_TYPES.GET_CARD_VALUE_ERROR:
            return {
                ...state,
                dashboardData: {},
            }
        case ACTION_TYPES.GET_CHART_DATA_SUCCESS:
            return {
                ...state,
                chartData: action.payload,
            }
        case ACTION_TYPES.GET_CHART_DATA_ERROR:
            return {
                ...state,
                chartData: [],
            }
        default: return state;
    }
}
export default DashboardReducer